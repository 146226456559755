<template>
  <div class="gondola-mini-apps">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'GondolaMiniIndex',
};
</script>
